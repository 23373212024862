import React from 'react'
import styled from 'styled-components'

import { Section, Text } from '../../components/Core'

import { Row, Col, Container } from 'react-bootstrap'

import Property from '../../components/Property'

import houseOne from '../../assets/ear/house_1.png'
import houseTwo from '../../assets/ear/house_2.png'

import Line from '../../components/Line'
import { device } from '../../utils'

const StyledSection = styled(Section)`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .title {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 50px;
    font-weight: 100;
    font-family: 'Vijaya';

  }

  .view-all {
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
    font-weight: 200;
  }

  @media ${device.sm} {
    .desktop {
      display: none;
    }
    .title {
      font-size: 25px;
      line-height: 30px;
    }
    .row-line {
      flex-direction: column !important;
    }

    .col {
      padding-bottom: 2rem;
    }
  }

  @media ${device.md} {
    padding-top: 50px !important;

    .desktop {
      display: none;
    }

    .col {
      padding-top: 25px;
    }
  }
  @media ${device.lg} {
    .row-line > div {
      border-right: 1px solid black;
    }
    .row-line > div:nth-child(2n) {
      border-right: none;
    }

    .desktop {
      display: block;
    }

    .col {
      padding-top: 50px;
    }
  }
`

const OurRentals = ({ title, textColor, properties, bg = 'light' }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}

      <StyledSection className="position-relative" bg={bg} hero>
        <Container>
          <div className="desktop">
            <Line />
          </div>
          <Text className="title mb-4" color={textColor}>
            Our Rental Properties
          </Text>

          <Row className="row-line">
            {properties.allListings.nodes.map((item, index) => {
              return (
                <Col xs={12} sm={12} md={6} className="col">
                  <Property
                    price={item.price}
                    bathroom={item.bathrooms}
                    car={`${item.carports + item.garages}`}
                    bedroom={item.bedrooms}
                    address={`${item.streetNo} ${item.street}, ${item.suburb} ${item.postcode}`}
                    image={item.mainImageUrl}
                    cutheight={true}
                    streetNo={item.streetNo}
                    street={item.street}
                    suburb={item.suburb}
                    postcode={item.postcode}
                  />
                </Col>

              );
            })}

          </Row>
        </Container>
      </StyledSection>
    </>
  )
}

export default OurRentals
