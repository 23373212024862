import React from 'react'

import PageWrapper from '../components/PageWrapper'
import OurRentals from '../sections/landing1/OurRentals'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

const ServicesPage = ({data}) => {
  return (
    <>
      <PageWrapper darkTheme={false}>
        <SEO title="Our Rentals" description="Find the perfect house, apartment, unit or granny flat for rent in Sydney - with East Avenue Realty."/>
        <OurRentals properties={data}/>
      </PageWrapper>
    </>
  )
}
export default ServicesPage


export const query = graphql`
query MyQuery {
  allListings(sort: {fields: id__normalized, order: DESC}) {
    nodes {
      price
      bathrooms
      bedrooms
      carports
      streetNo
      street
      suburb
      postcode
      mainImageUrl
      garages
    }
  }
}
`