import React, { useEffect, useContext } from 'react'

import GlobalContext from '../../context/GlobalContext'


const PageWrapper = ({ children, darkTheme = false, themeConfig = null, alwaysStick=false }) => {
  const gContext = useContext(GlobalContext)

  const themeConfigDefault = {
    headerDark: false,
    bodyDark: false,
    footerDark: false,
    alwaysStick: alwaysStick,
  }
  

  useEffect(() => {
    if (darkTheme) {
      gContext.changeTheme({
        headerDark: true,
        bodyDark: true,
        footerDark: true,
      })
    } else {
      gContext.changeTheme({ ...themeConfigDefault, ...themeConfig })
    }
  }, [])

  return <>{children}</>
}

export default PageWrapper
